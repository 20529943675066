import { useMediaQuery, useTheme } from "@mui/material";

interface UseResolutionsOutput{
    isMobileResolution:boolean,
    isTabletResolution:boolean,
    isLaptopResolution:boolean,
    isDesktopResolution:boolean
}

export default function useResolutions():UseResolutionsOutput{

    const theme = useTheme();
    const isMobileResolution:boolean = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
    const isTabletResolution:boolean = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
    const isLaptopResolution:boolean = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));
    const isDesktopResolution:boolean = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.xl));

    const output = {
        isMobileResolution,
        isTabletResolution,
        isLaptopResolution,
        isDesktopResolution
    };

    return(output);

}