import { Box, Grid, Typography } from "@mui/material";
import ImageWithOverlay from "../components/imageWithOverlay";
import { imageBorderRadius, pathToFile } from "../constants";
import HomepageContactUs from "./homepage-components/homepageContactUs";

const allPhotoImages:Array<string> = [
    pathToFile("salmon1.jpg"), pathToFile("salmon2.jpg"), pathToFile("salad.jpg"), pathToFile("salad2.jpg"), pathToFile("salad3.jpg"), pathToFile("salad4.jpg"), 
    pathToFile("sandwich3.jpg"), pathToFile("sandwich2.jpg"), pathToFile("burger.jpg"), pathToFile("fruit-bowl.jpg"),
    ...Array.from({length: 18}, (v, k) => pathToFile(`z${k+1}.jpg`))
];

const photoSize:string = "100%";
const PhotoGallery = ():JSX.Element=>{

    return(
        <Box>
            <ImageWithOverlay source={pathToFile("party1.jpg")} imageBrightness="30%">
                <Typography variant="h2" color="secondary" textAlign="center" p={3}>Check Out Your Next Meal!</Typography>
            </ImageWithOverlay>
            {
                <Grid container>
                    {
                        allPhotoImages.map(
                            imageSource => 
                                <Grid key={imageSource} item xs={12} sm={6} md={4} lg={3} p={2}>
                                    <Box component="img" src={imageSource} width={photoSize} height={photoSize} borderRadius={imageBorderRadius}/>
                                </Grid>
                        )
                    }
                </Grid>
                
            }
            <HomepageContactUs/>
        </Box>
    )
}

export default PhotoGallery;