import { Box, Grid, SxProps, Typography, useTheme } from "@mui/material";
import useResolutions from "../hooks/useResolutions";
import { useMemo } from "react";

export interface SellingPointProps{
    title:string,
    imageSource:string,
    description:string,
    last?:boolean
}

const imageSize:string = "100px";
const marginBetweenElements:number = 2;
const descriptionMargin:number = 5;

const borderWidth:string = "1px";

export const SellingPoint = (props:SellingPointProps):JSX.Element=>{

    const {title,imageSource,description,last} = props;

    const theme = useTheme();
    const resolutions = useResolutions();

    const borderStyles = useMemo(():SxProps=>{

        const border:string = `${borderWidth} solid ${theme.palette.primary.contrastText}`;

        if(!last){
            //first two sections
            if(resolutions.isTabletResolution){
                //borders should be on bottom
                return {borderBottom:border}
            }else{
                return {borderRight:border}
            }            
        }
        return {}
    },[last, resolutions.isTabletResolution, theme.palette.primary.contrastText]);

    return(
        <Grid 
        container 
        direction="column" 
        height="inherit" 
        sx={{...borderStyles,padding:resolutions.isTabletResolution ? `60px 0` : "0"}}>
            <Grid item mb={marginBetweenElements} >
                <Typography variant="h5" p={0} color="secondary">{title}</Typography>
            </Grid>
            <Grid item mb={marginBetweenElements}>
                <Box
                width={imageSize}
                height={imageSize}
                component="img" 
                sx={{borderRadius:"50%"}} 
                src={imageSource}/>
            </Grid>
            <Grid item mr={descriptionMargin} ml={descriptionMargin}>
                <Typography variant="h6" p={0} color="secondary">{description}</Typography>
            </Grid>
        </Grid>
    )

}

