import { Box, Grid, Link, Typography } from "@mui/material";
import { cervantesAddress, cervantesEmail, cervantesFullName, cervantesPhoneFormatted, cervantesPhoneClicked } from "../constants";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import useResolutions from "../hooks/useResolutions";
import { useCallback } from "react";

interface ContactInformationType{
    icon:JSX.Element,
    text:string,
    href?:string
}

const textMargin:number = 10;
const contactText:Array<ContactInformationType> = [
    {
        icon:<EmailIcon color="secondary"/>,
        text:cervantesEmail,
        href:`mailto:${cervantesEmail}`
    },
    {
        icon:<PhoneAndroidIcon color="secondary"/>,
        text:cervantesPhoneFormatted,
        href:cervantesPhoneClicked
    },
    {
        icon:<LocationOnIcon color="secondary"/>,
        text:cervantesAddress
    }
]

const ContactInformation = ():JSX.Element =>{

    const resolutions = useResolutions();

    const textVariant = resolutions.isMobileResolution ? "body2" : "h6";

    const renderContactInfo = useCallback((info:ContactInformationType):JSX.Element=>{
        if(info.href){
            return <Link variant={textVariant} color="secondary" href={info.href}>{info.text}</Link>
        }
        return <Typography variant={textVariant} color="secondary">{info.text}</Typography>
    },[textVariant]);

    return(
        <Box>
            <Box mb={textMargin}>
                <Typography variant="h4" color="secondary" fontWeight="bold">{cervantesFullName}</Typography>
            </Box>
            <Grid container>
                {
                    contactText.map(
                        (info:ContactInformationType) => 
                            <Grid item xs={12} mb={textMargin} key={info.text} textAlign="center">
                                <Box display="flex" justifyContent="center" textAlign="center">
                                    <Box ml={-2} display="flex" alignItems="center" justifyContent="center">{/* negative margin is a 'hack' for centering the text with icon */}
                                    {info.icon}
                                    </Box>
                                    <Box ml={1}>
                                        {renderContactInfo(info)}
                                    </Box>
                                </Box>
                            </Grid>
                    )
                }
            </Grid>
            
        </Box>
        
    )

}

export default ContactInformation;