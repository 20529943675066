import { Box, Button, Card, CardContent, Grid, IconButton, Rating, Slide, SxProps, Typography, useTheme } from "@mui/material";
import { cateringText, cuisinesText, doneText, galleryText, ingredientsText, pathToFile, photoText, rightText } from "../constants";
import { useNavigation } from "../hooks/useNavigation";
import { photoGalleryNavigation } from "../navigation/config";
import { SellingPoint } from "../components/sellingPoint";
import ImageWithOverlay from "../components/imageWithOverlay";
import useResolutions from "../hooks/useResolutions";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useCallback, useState } from "react";
import SideScrollElements from "../components/sideScrollElements";
import HomepageContactUs from "./homepage-components/homepageContactUs";

const Homepage = ():JSX.Element =>{

    return(
        <Box>
            <HomepageFirstSection/>
            <HomepageFirstDivider/>
            <HomepageSecondSection/>
            <HomepageContactUs/>
        </Box>
        
    )
}

export default Homepage;


const headerSubtext:string = `Offering the finest ${ingredientsText} and ${cuisinesText}`;
const homepageFirstSectionMarginBottom:number = 2;

const HomepageFirstSection = ():JSX.Element =>{

    const navigator = useNavigation();
    const resolutions = useResolutions();

    return(
        <ImageWithOverlay source={resolutions.isTabletResolution ? pathToFile("catering.jpg") : pathToFile("dinnerVideo.mp4")} isVideo={!resolutions.isTabletResolution}>
            <Grid container direction="column" alignContent="center" textAlign="center" p={3} sx={{paddingTop:{xs:15, md:3}}}>
                <Grid item mb={homepageFirstSectionMarginBottom}>
                    <Typography variant="h2" color="secondary">
                        {`${cateringText}. ${doneText}. ${rightText}.`}
                    </Typography>
                </Grid>
                <Grid item mb={homepageFirstSectionMarginBottom}>
                    <Typography variant="h6" color="secondary">
                        {headerSubtext}
                    </Typography>
                </Grid>
                <Grid item mb={homepageFirstSectionMarginBottom}>
                    <Box mt={homepageFirstSectionMarginBottom}>
                        <Button variant="contained" color="secondary" onClick={()=>navigator.navigate(photoGalleryNavigation.url)}>
                            {`${photoText} ${galleryText}`}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </ImageWithOverlay>
    );

}

const HomepageFirstDivider = ():JSX.Element=>{

    const theme = useTheme();

    return(
        <Box width="100%" textAlign="center" bgcolor={theme.palette.primary.main} p={5}>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <SellingPoint 
                    title="Serves All Party Sizes"
                    description="Planning a datenight? A large event? We got you covered!"
                    imageSource={pathToFile("burger.jpg")}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SellingPoint 
                    title="Variety"
                    description="Ranging between Scandinavia and Filipino cuisines, we serve it all!"
                    imageSource={pathToFile("variety.jpg")}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SellingPoint 
                    title="Health Restrictions"
                    description="Need gluten-free? Vegan? Allergies? No problem!"
                    imageSource={pathToFile("fruit-bowl.jpg")}
                    last/>
                </Grid>
            </Grid>
        </Box>
    )
}

interface HomepageReviewProps{
    name:string,
    imageSource:string,
    starReview:number,
    description:string
}

const allHomePageReviews:Array<HomepageReviewProps> = [
    {
        name:"Jessic K.",
        imageSource:pathToFile("smiling2.jpg"),
        starReview:5,
        description:"Chef Cervantes had preparded everything perfectly and made our family reunion very special."
    },
    {
        name:"Carl P.",
        imageSource:pathToFile("smiling1.jpg"),
        starReview:5,
        description:"Very nice staff and delicious food!"
    },
    {
        name:"Angela S.",
        imageSource:pathToFile("smiling3.jpg"),
        starReview:4,
        description:"Great for weddings! They were able to accommodate for different allergies and diets."
    }
];
const homepageReviewImageSize:string = "200px";
const iconButtonStyles:SxProps = {fontSize:"50px"};
const homepageReviewMargin:number = 3;
const HomepageSecondSection = ():JSX.Element=>{

    const [currentReview, setCurrentReview] = useState<number>(0);
    const [slideDirection, setSlideDirection] = useState<"left"|"right">("left");

    const resolutions = useResolutions();

    const changeReview = useCallback((nextReview:boolean):void=>{
        setSlideDirection(nextReview ? "left" : "right");
        const incrementor:number = nextReview ? 1 : -1;
        let newReviewNumber = currentReview + incrementor;
        if(newReviewNumber < 0){
            newReviewNumber = allHomePageReviews.length + newReviewNumber;
        }
        setCurrentReview(newReviewNumber % allHomePageReviews.length);
    },[currentReview]);

    return(
        <Box textAlign="center" sx={{overflow: 'hidden'}}>
            <Box p={4}>
                <Typography variant="h3">Don't Just Take Our Word For It!</Typography>
            </Box>
            <Grid container justifyContent="space-around">
                {
                    !resolutions.isMobileResolution &&
                    <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                        <IconButton onClick={()=>changeReview(false)}><ArrowBackIosNewIcon sx={iconButtonStyles}/></IconButton>
                    </Grid>
                }
                <Grid item xs={10} sm={6}>
                    {
                        allHomePageReviews.map(
                            (props:HomepageReviewProps,index:number) => 
                                <Slide key={props.name} direction={slideDirection} in={index === currentReview}>
                                    {
                                        (index === currentReview &&
                                        <Card>
                                            <CardContent>
                                                <Grid container direction="column">
                                                    <Grid item mb={homepageReviewMargin}>
                                                        <Typography variant="h5">{props.name}</Typography>
                                                    </Grid>
                                                    <Grid item mb={homepageReviewMargin}>
                                                        <Box component="img" src={props.imageSource} width={homepageReviewImageSize} height={homepageReviewImageSize} borderRadius="50%"/>
                                                    </Grid>
                                                    <Grid item mb={homepageReviewMargin}>
                                                        <Rating name="read-only" value={props.starReview} readOnly/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body1">{props.description}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card> )
                                        || <Box/>
                                    }
                                </Slide>
                        )
                    }
                </Grid>
                {
                    !resolutions.isMobileResolution &&
                    <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                        <IconButton onClick={()=>changeReview(true)}><ArrowForwardIosIcon sx={iconButtonStyles}/></IconButton>
                    </Grid>
                }
                
            </Grid>
            <Box height="76px" display="flex" justifyContent="center" alignItems="center">
                {
                    !resolutions.isMobileResolution &&
                    <SideScrollElements totalElements={allHomePageReviews.length} currentElementIndex={currentReview}/>
                }
            </Box>
        </Box>
    )
}