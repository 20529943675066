import { galleryText, homepageText, menuText, orderText } from "../constants";
import CervantesMenu from "../pages/cervantesMenu";
import Homepage from "../pages/homepage";
import OrderNow from "../pages/orderNow";
import PhotoGallery from "../pages/photoGallery";
import { NavigationBarButtonProps } from "./navigationBar";

export const homepageNavigation:NavigationBarButtonProps = {label:homepageText, url:"", component:<Homepage/>};
export const photoGalleryNavigation:NavigationBarButtonProps = {label:galleryText, url:galleryText, component:<PhotoGallery/>};
export const menuNavigation:NavigationBarButtonProps = {label:menuText, url:menuText, component:<CervantesMenu/>};
export const orderNavigation:NavigationBarButtonProps = {label:orderText, url:orderText, component:<OrderNow/>};

export const navigationBarButtons:Array<NavigationBarButtonProps> = [
    photoGalleryNavigation,
    menuNavigation
];

export const allNavigationLinks:Array<NavigationBarButtonProps> = [
    homepageNavigation,
    ...navigationBarButtons,
    orderNavigation
];