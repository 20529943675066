
import { Box, useTheme } from "@mui/material";
import { useMemo } from "react";

interface SideScrollElementsProps{
    totalElements:number,
    currentElementIndex:number
}

const dotSize:string = "5px";
const SideScrollElements = (props:SideScrollElementsProps):JSX.Element =>{

    const {totalElements,currentElementIndex} = props;

    const theme = useTheme();

    const renderComponents = useMemo(():Array<JSX.Element>=>{
        const output:Array<JSX.Element> = [];
        for(let i=0;i<totalElements;i++) {
            output.push(
                <Box 
                display="inline-block" 
                key={`dot-number-${i}`} 
                m={1} 
                width={dotSize} 
                height={dotSize} 
                bgcolor={i === currentElementIndex ? theme.palette.primary.main : theme.palette.secondary.dark} 
                borderRadius="50%"/>
            );
        }
        return output;
    },[currentElementIndex, theme.palette.primary.main, theme.palette.secondary.dark, totalElements]);

    return (
        <Box>
            {renderComponents}
        </Box>
    )

}

export default SideScrollElements;