import { Box, Grid, useTheme } from "@mui/material";
import ContactInformation from "../../components/contactInformation";

const HomepageContactUs = ():JSX.Element=>{

    const theme = useTheme();

    return(
        <Box width="100%" textAlign="center" bgcolor={theme.palette.primary.main} p={5}>
            <Grid container>
                {
                    <Grid item xs={12}>
                        <Box height="100%" width="100%">
                            <ContactInformation/>
                        </Box>
                    </Grid>
                }
            </Grid>
        </Box>
    )
}

export default HomepageContactUs;