import { Box, Grid, Typography, useTheme } from "@mui/material";
import { imageBorderRadius, menuIsCustomizedBodyText, menuIsCustomizedTitleText, pastText, pathToFile } from "../constants";
import ImageWithOverlay from "../components/imageWithOverlay";
import HomepageContactUs from "./homepage-components/homepageContactUs";
import { menuNavigation } from "../navigation/config";
import MenuCard, { MenuCardProps } from "./menu-components/menuCard";
import useResolutions from "../hooks/useResolutions";
import { useMemo } from "react";

const pastMenus:Array<MenuCardProps> = [
    {
        title:"Norwegian Breakfast",
        items:[
            {
                description:"Smorrebrod (GF) with vegan cream cheese, smoked salmon, red onions, dill, and capers",
            },
            {
                description:"Barley porridge (GF, Vegan) served with fresh berries and cinnamon sugar mix",
            },
            {
                description:"Smoothie (Vegan)",
            },
            {
                description:"Danishes",
            },
            {
                description:"Fruit tray",
            },
            {
                description:"Cheese tray",
            },
            {
                description:"Coffee/Tea/Juice",
            },
        ],
        extraImages:[pathToFile("z7.jpg")]
    },
    {
        title:"Norwegian Lunch",
        items:[
            {
                description:"Beef Sliders with tomatoes, pickles, cheese and house sauce",
            },
            {
                description:"Salmon canopies with smoked salmon, tobiko and horseradish cream sauce",
            },
            {
                description:"Stuffed mushrooms with italian sausage and roasted garlic tomato pure",
            },
            {
                description:"Cucumber cups with ahi tuna tartare, wasabi aioli, and tobiko",
            },
            {
                description:"Cheese tray with crackers and bread",
            },
            {
                description:"Coffee and mini desserts ",
            },
        ],
        extraImages:[pathToFile("z9.jpg"),pathToFile("z10.jpg")]
    },
    {
        title:"Swedish Delight",
        items:[
            {
                description:"Swedish Meatballs",
            },
            {
                description:"Fried wonton with ahi tartare avocado puree with tobiko",
            },
            {
                description:"Bruschetta with goat cheese and olive tapenade",
            },
            {
                description:"Shrimp cups with cocktail sauce and avocado pure",
            },
            {
                description:"Poached pear cups with goat cheese with a red wine glaze",
            },
            {
                description:"Spanakopitas",
            },
            {
                description:"Cheese tray",
            },
            {
                description:"Coffee and mini desserts",
            },
        ],
        extraImages:[pathToFile("z8.jpg"),pathToFile("z6.jpg")]
    },
];
const titleMargin:number = 3;
const menuPadding:number = 3;
const bodyImageSize:string = "400px";
const CervantesMenu = ():JSX.Element =>{

    const theme = useTheme();
    const resolutions = useResolutions();

    const onlyRenderBodyText = useMemo(():boolean=>{
        return resolutions.isTabletResolution
    },[resolutions.isTabletResolution]);

    return (
        <Box>
            <ImageWithOverlay source={pathToFile("event1.jpg")}>
                <Box mt={8} p={4}>
                    <Typography textAlign="center" color="secondary" variant="h3">{menuIsCustomizedTitleText}</Typography>
                </Box>
                
            </ImageWithOverlay>
            <Box width="100%" textAlign={"center"} bgcolor={theme.palette.primary.main}>
                <Grid container p={3} justifyContent="center" alignItems="center" pt={10} pb={10}>
                    <Grid item xs={12} sm={6} p={4}>
                        <Typography variant="h5" color="secondary" lineHeight={2}>{menuIsCustomizedBodyText}</Typography>
                    </Grid>
                    {
                        !onlyRenderBodyText &&
                        <Grid item sm={6}>
                            <Box component="img" src={pathToFile("z10.jpg")} height={bodyImageSize} width={bodyImageSize} borderRadius={imageBorderRadius}/>
                        </Grid>
                    }
                </Grid>
            </Box>
            <Box>
                <Box textAlign="center" mb={titleMargin} mt={titleMargin+2}>
                    <Typography variant="h2">{`${pastText} ${menuNavigation.label}`}</Typography>
                </Box>
                <Grid container justifyContent="space-around">
                    {
                        pastMenus.map(
                            (menuCardProps:MenuCardProps) =>
                                <Grid item sm={12} md={6} lg={4} key={menuCardProps.title} p={menuPadding}>
                                    <MenuCard {...menuCardProps}/>
                                </Grid>
                        )
                    }
                </Grid>
            </Box>
            <HomepageContactUs/>
        </Box>
    )

}

export default CervantesMenu;