
import { PaletteColorOptions, ThemeOptions } from "@mui/material";
import { green, red } from "@mui/material/colors";

const themeError:PaletteColorOptions  = {
    main:red[700],
    dark:red[900],
    contrastText:"#FFF",
    light:red[500]
};
  
const themeSuccess:PaletteColorOptions  = {
    main: green[700],
    dark:green[900],
    contrastText:"#FFF",
    light:green[500]
}

export const sharedTheme:ThemeOptions = {
    palette:{
        error:themeError,
        success:themeSuccess
    },
    components:{
        MuiCssBaseline:{
            styleOverrides:{
                body:{
                    margin:0
                }
            }
        },
        MuiTextField:{
            defaultProps:{
                sx:{
                    backgroundColor:"#FFFFFF",
                    borderRadius: "4px"
                }
            }
        },
        MuiTypography:{
            defaultProps:{
                //fontFamily:"Brush Script MT, Brush Script Std, cursive"
            }
        },
        MuiCard:{
            defaultProps:{
                elevation:5,
                sx:{
                    borderRadius:"20px"
                }
            }
        }
    }
};