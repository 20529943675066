import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { imageBorderRadius } from "../../constants";

export interface MenuCardProps{
    title:string,
    items:Array<MenuItem>,
    extraImages?:Array<string>
}

interface MenuItem{
    description:string
}

const menuItemImageSize:string = "100%";
const maxMenuItemImageSize:string = "500px";
const titleMargin:number = 3;
const descriptionMargin:number = 2;
const extraImagesPadding:number = 2;
const MenuCard = (props:MenuCardProps):JSX.Element =>{

    const {title,items,extraImages} = props;

    return(
        <Card>
            <CardContent>
                <Grid container textAlign="center" justifyContent="center">
                    <Grid item mt={titleMargin} mb={titleMargin}>
                        <Typography variant="h4" sx={{textDecoration:"underline"}}>{title}</Typography>
                    </Grid>
                    {
                        items.map(
                            (item:MenuItem)=>
                            <Grid container item key={item.description} mt={descriptionMargin} mb={descriptionMargin}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" fontStyle="italic">{item.description}</Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        extraImages && 
                        extraImages.map(
                            (imageSource:string) =>
                            <Grid item xs={12} md={6} key={imageSource} mt={descriptionMargin} p={extraImagesPadding}>
                                <Box 
                                component="img" 
                                src={imageSource} 
                                maxWidth={maxMenuItemImageSize} width={menuItemImageSize} 
                                maxHeight={maxMenuItemImageSize} height={menuItemImageSize} 
                                borderRadius={imageBorderRadius}/>
                            </Grid>
                        )
                    }
                </Grid>
            </CardContent>
        </Card>
    )

}

export default MenuCard;