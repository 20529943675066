import {Box} from "@mui/material";
import Header from "./navigation/header";
import Router from "./navigation/router";

function App() {
  return (
    <>
      <Box>
        <Header/>
      </Box>
      <Router/>
    </>
  );
}

export default App;
