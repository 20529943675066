
import {configureStore} from "@reduxjs/toolkit";
import isLoading from "./isLoading";

const store = configureStore(
    {
        reducer:{
            isLoading,
        }
    }
);

export type StoreType = ReturnType<typeof store.getState>;

export default store;