import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigation } from "../hooks/useNavigation";
import { navigationBarButtons } from "./config";
import useResolutions from "../hooks/useResolutions";

export interface NavigationBarButtonProps{
    label:string,
    url:string,
    component:JSX.Element
}

const defaultButtonSize = "medium";
const NavigationBar = ():JSX.Element => {

    const navigator = useNavigation();
    const resolutions = useResolutions();
    
    //check the url; if the user 'refreshed' the screen, then highlight the button that the user was on last, otherwise default to homepage
    const [currentlySelected,setCurrentlySelected] = useState<string>(navigationBarButtons[0].url);

    useEffect(()=>{
        const trimmedUrlPath = navigator.fullUrlPath().slice(1);
        setCurrentlySelected(trimmedUrlPath);
    },[navigator, navigator.fullUrlPath]);

    const handleClick = useCallback((url:string):void=>{
        setCurrentlySelected(url ?? currentlySelected);
        navigator.navigate(url);
    },[currentlySelected, navigator]);

    return(
        <ToggleButtonGroup 
        color="primary" 
        value={currentlySelected} 
        exclusive
        onChange={(event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => handleClick(value)}
        sx={{bgcolor:"white"}}>
            {
                navigationBarButtons.map((button:NavigationBarButtonProps):JSX.Element=>
                <ToggleButton size={resolutions.isMobileResolution ? "small" : defaultButtonSize} key={`${button.label} ${button.url}`} value={button.url}>
                    {button.label}
                </ToggleButton>
                )
            }
        </ToggleButtonGroup>
        
    )
}

export default NavigationBar;