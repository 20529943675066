
import {Box, Grid, Link, useTheme} from "@mui/material";
import NavigationBar from "./navigationBar";
import Logo from "../components/logo";
import useResolutions from "../hooks/useResolutions";
import { cervantesPhoneClicked, cervantesPhoneFormatted } from "../constants";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

const headerTopBottomPadding:number = 3;

const Header = ():JSX.Element=>{

    const theme = useTheme();
    const resolutions = useResolutions();

    return(
        <Grid 
        container 
        alignItems="center" 
        pt={headerTopBottomPadding} 
        pb={headerTopBottomPadding} 
        bgcolor={`${theme.palette.primary.main}66`}
        textAlign="center"
        justifyContent="center"
        position="absolute" top="0" zIndex="10"
        >
            {
                
                <Grid item xs={!resolutions.isMobileResolution ? 3 : 3}>
                    <Logo/>
                </Grid>
            }
            <Grid item xs={!resolutions.isMobileResolution ? 6 : 6}>
                <NavigationBar/>
            </Grid>
            {
                
                <Grid item xs={!resolutions.isMobileResolution ? 3 : 3}>  
                    <Box display="flex" justifyContent="center" textAlign="center" alignItems="center">
                        {
                            !resolutions.isMobileResolution && <PhoneAndroidIcon color="secondary"/>
                        }
                        
                        <Link ml={1} variant={resolutions.isMobileResolution ? "body2" : "h5"} color="secondary" href={cervantesPhoneClicked}>{cervantesPhoneFormatted}</Link>
                    </Box>
                </Grid>
            }
            
        </Grid>
    )
}

export default Header;