
import { Route, Routes } from "react-router-dom";
import { NavigationBarButtonProps } from "./navigationBar";
import { allNavigationLinks } from "./config";

const Router = ():JSX.Element =>{

    return(
        <Routes>
            {
                allNavigationLinks.map(
                    (url:NavigationBarButtonProps) =>
                        <Route key={url.label} path={url.url} element={url.component}/>
                )
            }
        </Routes>
        
    )
}

export default Router;