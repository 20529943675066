import { PaletteColorOptions, createTheme  } from "@mui/material";
import { sharedTheme } from "./sharedTheme";

const primaryColors:PaletteColorOptions = {
    main:"#000000",
    contrastText:"#FFFFFF"
};

const secondaryColors:PaletteColorOptions = {
    main:"#FFFFFF",
    contrastText:"#000000"
};

export const blackAndWhiteTheme = createTheme({
    palette:{
        primary:primaryColors,
        secondary:secondaryColors
    }
}, sharedTheme);