
import { createSlice } from "@reduxjs/toolkit";

export const isLoadingSlice = createSlice({
    name:"isLoading",
    initialState:false,
    reducers:{
        setIsLoading:(state:boolean,action):boolean=>{
            state = action.payload
            return(state);
        }
    }
});

export const {setIsLoading} = isLoadingSlice.actions;

export default isLoadingSlice.reducer;
