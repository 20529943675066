import { Box } from "@mui/material";
import useResolutions from "../hooks/useResolutions";
import { useNavigation } from "../hooks/useNavigation";
import { homepageNavigation } from "../navigation/config";

const logoSize:string = "70px";

const Logo = ():JSX.Element =>{

    const resolutions = useResolutions();
    const navigator = useNavigation();

    return(
        <Box
        component="img"
        mt={1}
        onClick={()=>navigator.navigate(homepageNavigation.url)}
        height={!resolutions.isMobileResolution ? logoSize : "40px"}
        src="icon.png"
        borderRadius="20%"
        sx={{"&:hover":{cursor:"pointer"}}}
        />
    )
}

export default Logo;