
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface useNavigationOutput{
    navigate:(url:string)=>void,
    fullUrlPath:()=>string
}

export function useNavigation():useNavigationOutput{

    const navigate = useNavigate();

    const fullUrlPath = useCallback(():string => {
        return(document.location.pathname);
    },[]);

    return {
        navigate,
        fullUrlPath
    }

}