
const OrderNow = ():JSX.Element=>{

    return(
        <>
            Order Now Page
        </>
    )
}

export default OrderNow;