import { Box } from "@mui/material";
import { setBrightness } from "../util";
import ReactPlayer from "react-player";
import { useMemo } from "react";

interface ImageWithOverlayProps{
    source:string,
    imageBrightness?:string,
    isVideo?:boolean,
    children:JSX.Element
}

export const minHeightImageOverlay:string = "500px";
export const maxHeightImageOverlay:string = "750px";
const ImageWithOverlay = (props:ImageWithOverlayProps):JSX.Element =>{

    const {source,imageBrightness,isVideo,children} = props;

    const renderImageOrVideo = useMemo(():JSX.Element=>{
        if(isVideo){
            return (
                <ReactPlayer
                volume={0}
                playing={true}
                loop={true}
                url={source}
                width="100%"
                height="100%"
                style={setBrightness(imageBrightness) as any}
                />
            );
        }
        return(
            <Box
            component="img"
            width="100%"
            minHeight={minHeightImageOverlay}
            maxHeight={maxHeightImageOverlay}
            src={source}
            sx={{...setBrightness(imageBrightness)}}/>
    );
    },[imageBrightness, isVideo, source]);

    return(
        <Box position="relative" lineHeight={0}>
            <Box position="absolute" width="100%" top="50%" zIndex={1} sx={{transform:"translateY(-50%)"}}>
                {children}
            </Box>
            {renderImageOrVideo}
        </Box>
    )
}

export default ImageWithOverlay;