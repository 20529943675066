

// Page information
export const homepageText:string = "Homepage";
export const photoText:string = "Photo";
export const galleryText:string = "Gallery";
export const menuText:string = "Menus";

//ordering information
export const orderText:string = "Order";
export const nowText:string = "Now";

//homepage contents
export const cateringText:string = "Catering";
export const doneText:string = "Done";
export const rightText:string = "Right";

export const ingredientsText:string = "Ingredients";
export const cuisinesText:string = "Cuisines";

//CervantesMenu contents
export const menuIsCustomizedTitleText:string = "Personalized Menus for Your Special Events!";
export const menuIsCustomizedBodyText:string = "All menus are custom tailored. We can prepare any cuisine for any occasion: weddings, barbecues, parties, and more. Check out some of our past catering menus!";

//contact form
export const contactUsText:string = "Contact Us";
export const customerFullNameText:string = "Full Name";
export const customerEventDateText:string = "Event Date";
export const customerEmailText:string = "Email";
export const customerPhoneText:string = "Phone";
export const customerMessageText:string = "Message";
export const submitText:string = "Submit";

//Contact Information
export const cervantesFullName:string = "Ricardo Cervantes Jr.";
export const cervantesEmail:string = "ricardo@chefcervantescatering.com";

const cervantesRawPhoneNumber:string = "4159170565";
export const cervantesPhoneFormatted:string = `(${cervantesRawPhoneNumber.slice(0,3)}) ${cervantesRawPhoneNumber.slice(3,6)} - ${cervantesRawPhoneNumber.slice(6)}`;
export const cervantesPhoneClicked:string = `tel:${cervantesRawPhoneNumber.slice(0,3)}-${cervantesRawPhoneNumber.slice(3,6)}-${cervantesRawPhoneNumber.slice(6)}`;
export const cervantesAddress:string = "20 Woodside Ave, San Francisco, CA 94127";

//images
export const pathToFile = (fileName:string):string => `images/${fileName}`;
export const imageBorderRadius:string = "20%";

//errors
export const featureUnavailable:string = `Please contact us at ${cervantesEmail} for inqueries on catering events or school lunches.`;

//generic
export const pastText = "Past";